import React from 'react'
import { Container } from 'react-bootstrap';

var Error404 = (props) => {

    return (
        <Container>
            <h1 className="error">
                <p className="code">Pàgina no trobada al servidor! 404</p>
                <span>La pàgina solicitada no existeix</span>
            </h1>
        </Container>
    );
}

export default Error404