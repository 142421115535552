import React from 'react';
import './assets/scss/App.scss';
import Router from './Router';
import Footer from './components/layout/Footer';
import RGPD from './components/layout/CookieRGPD';

const App = ({ props }) => {
 
  return (

    <div>      
        <Router></Router>
        <Footer />
        <RGPD />
    </div>


  );
}

export default App;
