import React from 'react'
import { Container } from 'react-bootstrap';

var Error500 = (props) => {

    return (
        <Container>
            <h1 className="error">
                <p className="code">Error 500! Servidor no respón</p>
                <span>La conexió i/o la obtenció de dades amb el servidor remot a fallat.</span>
            </h1>
        </Container>
    );
}

export default Error500