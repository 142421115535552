import React from 'react';
import { connect } from 'react-redux';
import FormConsultaRedux from './FormConsulta';
import ResultConsultaRedux from './ResultConsulta';

const MainResultats = (props) => {
    
    return (
        <div>
            <div className="row">
                <div id="formCard" className={
                    (props.booking.status !== 'success' && props.booking.status !== 'error') ? 
                    'col-sm-6 offset-sm-3' : 'col-sm-4'
                }>
                   <FormConsultaRedux />
                  
                </div>
                {props.booking.status !== 'void' && props.booking.status !== 'fetching' &&
                <div className="col-sm-8">
                     <ResultConsultaRedux />
                </div>  
                }
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
const MainResultatsRedux = connect(mapStateToProps, mapDispatchToProps)(MainResultats)
export default MainResultatsRedux;