import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './components/layout/Header.js';
import Error404 from './components/errors/Error404';
import Error500 from './components/errors/Error500';
import Error403 from './components/errors/Error403';
import store from './redux/store';
import { Provider } from 'react-redux';
import MainResultats from './components/resultats/MainResultats.js';
import { Container } from 'react-bootstrap';
import Survey from './components/survey/Survey.js';
import Horari from './components/horari/Horari.js';
import HorariGrup from './components/horari/HorariGrup.js';


class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <div id="main">
                    <Header />
                    <Container>
                        <Provider store={store}>
                            <Switch>
                                <Route exact path="/" component={MainResultats}></Route>
                                <Route exact path="/survey" component={Survey}></Route>
                                <Route exact path="/horari" component={Horari}></Route>
                                <Route exact path="/horari/grup/:grup_id" component={HorariGrup}></Route>
                            
                                <Route exact path="/errors/500" component={Error500}></Route>
                                <Route exact path="/errors/403" component={Error403}></Route>
                                <Route component={Error404}></Route>
                            </Switch>
                        </Provider>
                    </Container>
                </div>
            </BrowserRouter>
        )
    }
}

export default Router;