export const NIF = 'Nif';

const NifReducer = (state = null, action) => {
    switch (action.type) {
        case NIF:
            return  action.payload

        default:
            return state;
    }
}

export default NifReducer;