import React from 'react';
import Swal from 'sweetalert2';

/**
 * Funcions i variables auxiliars utilitzades a tota l'aplicació
 * @param {*} props 
 */
const useHelpers = (props) => {

    /**
     * Error para peticiones axios
     */
    function ErrorHttpRequest() {
        emitError('Error 500!', 'Error al intentar obtenir dades del servidor');
    }

    function maskString(text) {

        if (text) {
            const len = text.length < 15 ? text.length : 15;
            let first = text.substring(0, 2);
            const mask = '*'.repeat(len, -2);
            return first + mask;
        }

        return '*****';

    }

    /**
     * Executa un modal d'error genèric
     * @param {*} title 
     * @param {*} msg 
     * @param {*} callback 
     */
    function emitError(title, msg = '', callback = null) {

        Swal.fire({
            icon: 'error',
            title: title,
            text: msg,
            confirmButtonColor: 'blue',
            confirmButtonText: 'Acceptar'

        }).then(rs => {
            if (callback)
                callback();
        });

    }

    function emitInfoHorari(data) {

        Swal.fire({
            icon: 'info',
            title: data.materia_name,
            html: "<ul class='info-list'>" +
                '<li>Data: <b>' + data.fecha_formated_es + '</b></li>' +
                '<li>Horari: <b>' + data.init_formated + '-' + data.end_formated + '</b></li>' +
                '<li>Aula: <b>' + data.aula_name + '</b></li>' +
                '<li>Professor: <b>' + data.profe_name + '</b></li>' +
                '<li>Nota: <b>' + data.text + '</b></li>' +
                '</ul>',
            confirmButtonColor: 'blue',
            confirmButtonText: 'Acceptar'

        });
    }


    function emitSuccess(title, text = null) {

        Swal.fire({
            icon: 'success',
            title: title,
            text: text,
            confirmButtonColor: 'blue',
            confirmButtonText: 'Acceptar'

        });
    }

    /**
     * Dibuja las opciones de un selector simple
     * @param {*} combo 
     */
    function setOptions(combo) {
        let rs = [<option key={-1} value={null}></option>];
        combo.map((option, i) => {
            let op = <option key={i} value={option.value}>{option.label}</option>
            rs = [...rs, op];
        });
        return rs;
    }

    return { emitError, emitSuccess, setOptions, ErrorHttpRequest, maskString, emitInfoHorari };
}

export default useHelpers;