import React from 'react';
import { connect } from 'react-redux';
import CardSuccessRedux from './CardSuccess';
import ReactHtmlParser from 'react-html-parser';

const ResultConsulta = (props) => {
    const { booking } = props

    return (
        <>
            {
                booking.status === 'error' &&

                <div className="animate__animated animate__fadeInDown  animate__delay-1s">

                    <h5>{ReactHtmlParser(booking.error)}</h5>
                </div>
            }

            {booking.status === 'success' &&
                <>
                     <CardSuccessRedux />
                </>
            }

        </>
    )
}


const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const ResultConsultaRedux = connect(mapStateToProps, mapDispatchToProps)(ResultConsulta);

export default ResultConsultaRedux;