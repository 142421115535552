import {
    UNTIS_GRUPS_ERROR, 
    UNTIS_GRUPS_SUCCESS, 
    UNTIS_GRUPS_FETCHING,
    UNTIS_CURRENT_GRUP,
    UNTIS_HORARI_ERROR,
    UNTIS_HORARI_FETCHING,
    UNTIS_HORARI_SUCCESS
} from '../../reducers/Horari/HorariReducer';
import axios from 'axios';
import config from '../../../config';
import { REDIRECT } from '../../reducers/RedirectReducer';

export const GrupDispatch = () => (dispatch) =>{
    dispatch({type:UNTIS_GRUPS_FETCHING});

    axios.get(config.api_tcm.url + 'grups',config.api_tcm.headers_api).then(response => {    
        dispatch({
            type: UNTIS_GRUPS_SUCCESS,
            payload: response.data.data
        })

    }).catch(error => {
        dispatch({
            type: UNTIS_GRUPS_ERROR,
            payload: error.response
        })
    })
}

export const currentGrupDispatch = (id = null) => {
    return{
        type: UNTIS_CURRENT_GRUP,
        payload: id
    }
}

export const checkHorariDispatch = (id) => (dispatch) =>{
    dispatch({type:UNTIS_HORARI_FETCHING});

    axios.get(config.api_tcm.url + 'horaris/'+id,config.api_tcm.headers_api).then(response => {         

        dispatch({
            type: REDIRECT,
            payload: '/horari/grup/'+id

        });

    }).catch(error => {
        dispatch({
            type: UNTIS_HORARI_ERROR,
            payload: error.response
        })
        dispatch({
            type: UNTIS_CURRENT_GRUP,
            payload: null
        })
    })
}

export const getHorarisDispatch = (id,type_grup='group') => (dispatch)=>{
    dispatch({type:UNTIS_HORARI_FETCHING});

    axios.get(config.api_tcm.url + 'horaris/'+id+'?type_view='+type_grup,config.api_tcm.headers_api).then(response => {   
        
        let horaris = response.data.data;
        let calendar_params = horaris.map(item => {
            let message = 'assignatura: ' + item.materia_name + ' | aula: ' + item.aula_key + ' | professor: ' + item.profe_name;
            if(item.text) message = message + ' | ' + item.text;

            return {
                id: item.id,
                title: message,
                start: item.fecha_formated + ' ' +item.init_formated,
                end: item.fecha_formated + ' ' +item.end_formated,
                data: item
            }
        })
    
        dispatch({
            type: UNTIS_HORARI_SUCCESS,
            payload: calendar_params
        })

        dispatch({
            type: UNTIS_CURRENT_GRUP,
            payload: id
        })

    }).catch(error => {
        dispatch({
            type: UNTIS_HORARI_ERROR,
            payload: error.response
        })
        dispatch({
            type: UNTIS_CURRENT_GRUP,
            payload: null
        })
    })
}
