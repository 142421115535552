import React from 'react'
import { Row, Col } from 'react-bootstrap';
import config from '../../config'

var Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <Row>
                        <Col md={4} xs={12}>
                            <span>
                                &copy; Fundació Tecnocampus
            </span>
                        </Col>
                        <Col md={8} xs={12}>
                            <div className="pull-right">
                                <a href="https://www.tecnocampus.cat/ca/politica-privacidad" rel='noopener noreferrer' target="_blank">Política de privacitat</a>
                                <a href="https://www.tecnocampus.cat/ca/aviso-legal" rel='noopener noreferrer' target="_blank">Avís Legal</a>
                                <a href="https://www.tecnocampus.cat/ca/politica-de-cookies" rel='noopener noreferrer' target="_blank">Cookies</a>
                            </div>
                        </Col>
                    </Row>

                </div>

            </div>
            {config.environment === 'test' &&
                <div className="testing-advise">
                    estamos en testing
                </div>
            }

        </>
    );
}

export default Footer;