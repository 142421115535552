import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import mainReducer from './reducers';
import thunk from 'redux-thunk';

//Middleware
// const logger = (store) => (next) => (action) => {
//     console.log('ha ocurrido una acción', action);
//     next(action);
// }


/**
 * Configuración del devTools
 */


const composeEnhancers = composeWithDevTools(
    {
        trace: true,
        // traceLimit: 2,
        realTime: true,
        name: 'ChuskyRedux'
    });

    // Deshabilitamos devTools para producción
const devTools = process.env.REACT_APP_ENV === 'prod' ? applyMiddleware(thunk) : composeEnhancers(applyMiddleware(thunk));



/**
 * Creación del store con el devTools
 */
const store = createStore(
    mainReducer,
    devTools
);


export default store;