import React from 'react'
import { Container } from 'react-bootstrap';

var Error403 = (props) => {
  

    return (
        <Container>
            <h1 className="error">
                <p className="code">Accés denegat! 403</p>
                <span>No s'ha trobat cap token d'accés vàlid</span>
            </h1>
        </Container>
    );
}

export default Error403