import {QUESTIONS_SUCCESS, QUESTIONS_FETCHING, QUESTIONS_ERROR} from '../reducers/SurveyQuestionsReducer';
import axios from 'axios';
import config from '../../config'

const QuestionsDispatch = () => (dispatch) => {
    dispatch({type:QUESTIONS_FETCHING});

    axios.get(config.url_api_survey + 'questions',config.headers_api).then(response => {
        console.log(response.data);
        dispatch({
            type: QUESTIONS_SUCCESS,
            payload: response.data.data
        })
    }).catch(error => {
        dispatch({
            type: QUESTIONS_ERROR,
            payload: error.response.data.message
        })
    })

}

export default QuestionsDispatch;