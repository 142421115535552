import React, { useState, useEffect } from 'react'
import Pregunta from './Pregunta';
import QuestionsDispatch from '../../redux/actions/QuestionsActions';
import useHelpers from '../../hooks/useHelpers';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { SaveScoreDispatch } from '../../redux/actions/ScoreActions';

const Survey = (props) => {
    const { ErrorHttpRequest, emitError, emitSuccess } = useHelpers();
    const [grantSave, setGrantSave] = useState();

    useEffect(() => {
        props.getQuestions();
    }, []);

    const saveScore = () => {
        if (Object.keys(props.score).length === 0) {
            return emitError('Error al intentar guardar', "La puntuació no pot estar buida")
        } else {
            setGrantSave('grant');
            props.saveScore(props.score);
        }

    }

    const drawQuestions = () => {
        let rs = props.questions.data.map((item) => (
            <Pregunta
                text={item.pregunta}
                item={item}
            />
        ));

        return rs;
    }


    if (props.questions.status === 'error') {
        return (
            <>
                {ErrorHttpRequest()}
                <Redirect to="/errors/500" />
            </>
        )
    }

    if (props.sendScore.status === 'success') {
        return (
            <>
                {emitSuccess('Resposta enregistrada correctament')}
                <h1>Gràcies per participar a l'enquesta
                    <br/>
                    <small>Ja pots tancar la finestra</small>
                </h1>
        
            </>
        )
    }


    return (
        <>
            <LoadingOverlay style={{ 'position': 'fixed' }}
                active={props.questions.status === 'fetching' || props.sendScore.status === 'fetching'}
                spinner
                text='Consultant servidor...'
            />
            {props.questions.status === 'success' &&
                <>
                    {drawQuestions()}
                    <div className="row">
                        <button onClick={saveScore} className="btn btn-primary col-sm-12 btn-lg">Enviar resposta</button>
                    </div>
                </>
            }


        </>
    );
}
const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        getQuestions: () => dispatch(QuestionsDispatch()),
        saveScore: (score) => dispatch(SaveScoreDispatch(score)),
    }
}
const SurveyRedux = connect(mapStateToProps, mapDispatchToProps)(Survey);
export default SurveyRedux;