import React, { useState } from 'react'
import Emoticons from './Emoticons';
import StarRating from './StarRating';
import { connect } from 'react-redux';
import {ScoreDispatch}from '../../redux/actions/ScoreActions';

const Pregunta = (props) => {
    const { text } = props;
    const [hover_rating, setHoverRating] = useState(0);
    const [selected_rating, setSelectedRating] = useState(0);
    let background = "";

    switch (selected_rating) {
        case 1:
            background = "shock-face";
            break;
        case 2:
            background = "sad-face";
            break;
        case 3:
            background = "flat-face";
            break;
        case 4:
            background = "smile-face";
            break;
        case 5:
            background = "happy-face";
            break;
        default:
            background = "flat-face";
    }

    const handleOnClick = i => {
        setSelectedRating(i);
        props.setScore(props.item, i);
    };
    const handleOnMouseOver = i => {
        setHoverRating(i);
    };
    const handleOnMouseLeave = () => {
        setHoverRating(0);
    };

    return (
        <div>
            <div className={background}>
                <Emoticons
                    selected_rating={selected_rating}
                />
                <h2 className="label text-align-center">{text}</h2>
                <StarRating
                    starCount={5}
                    hover_rating={hover_rating}
                    selected_rating={selected_rating}
                    handleOnClick={handleOnClick}
                    handleOnMouseOver={handleOnMouseOver}
                    handleOnMouseLeave={handleOnMouseLeave}
                />
            </div>
            <hr />
        </div>
    )
}

const mapStateToProps = (state) => (state);
const mapDispatchToProps = (dispatch) => {
    return {
        setScore: (key, value) => dispatch(ScoreDispatch(key, value)),
    }
}

const PreguntaRedux = connect(mapStateToProps, mapDispatchToProps)(Pregunta);

export default PreguntaRedux;