import {SCORE, SCORE_FETCHING, SCORE_SUCCESS, SCORE_ERROR} from '../reducers/SurveyScoreReducer';
import axios from 'axios';
import config from '../../config'

export const ScoreDispatch = (item,value) => {
   return{
       type: SCORE,
       payload: {
           'code': item.key,
           'id': item.id,
           value
       }
    };
}

export const SaveScoreDispatch = (score) => (dispatch) => {
    dispatch({type:SCORE_FETCHING});

    axios.post(config.url_api_survey + 'questions',{'score':score},config.headers_api).then(response => {
        console.log(response.data);
        dispatch({
            type: SCORE_SUCCESS,
            payload: response.data
        })
    }).catch(error => {
        dispatch({
            type: SCORE_ERROR,
            payload: error.response.data.message
        })
    })

}
