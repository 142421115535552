export const SCORE = 'SCORE';

export const SCORE_SUCCESS = 'SaveScore';
export const SCORE_ERROR = 'SaveScoreError';
export const SCORE_FETCHING = 'SaveScoreFetching';

export const ScoreReducer = (state = {}, action) => {
    switch (action.type) {
        case SCORE:
            return {
                ...state,
                [action.payload.code]: action.payload
            }
        default: return state
    }
}

let initialState = {
    'status': 'void',
    'data': {},
    'error' : null
}

export const SaveScoreReducer = (state=initialState,action) => {
    switch (action.type) {
        case SCORE_SUCCESS:
            return{
                ...state,
                status: 'success',
                data: action.payload
            }
        case SCORE_FETCHING:
            return {
                ...state,
                status: 'fetching',
                data: []
            }
        case SCORE_ERROR:
            return {
                ...state,
                status : 'error',
                data: null,
                error: action.payload
            }
        default:
            return state;
    }
}
