export const REDIRECT = 'REDIRECT';

const RedirectReducer = (state = null, action) => {
    switch (action.type) {
        case REDIRECT:
            return  action.payload

        default:
            return state;
    }
}

export default RedirectReducer;