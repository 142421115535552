
export const CONSULTA_SUCCESS = 'Consulta';
export const CONSULTA_ERROR = 'ConsultaError';
export const CONSULTA_FETCHING = 'ConsultaFetching';

let initialState = {
    'status': 'void',
    'data': {},
    'error' : null
}

const ConsultaReducer = (state=initialState,action) => {
    switch (action.type) {
        case CONSULTA_SUCCESS:
            return{
                ...state,
                status: 'success',
                data: action.payload
            }
        case CONSULTA_FETCHING:
            return {
                ...state,
                status: 'fetching',
                data: []
            }
        case CONSULTA_ERROR:
            return {
                ...state,
                status : 'error',
                data: null,
                error: action.payload
            }
        default:
            return state;
    }
}

export default ConsultaReducer;