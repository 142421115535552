var config = ({   
    'url_api' : process.env.REACT_APP_API_HOST,
    'url_api_survey' : process.env.REACT_APP_API_SURVEY,
    'iframe' : process.env.REACT_APP_IFRAME,
    'headers_api' : { headers: {
        'Authorization': 'Bearer $2y$10$HlNraRAMYuJSGkY5R06EI.qMHAbFs0V6aXrq4212JP1SW0iOHNwYW',
        'Content-Type' : 'application/json',
    }},
    'environment' : process.env.REACT_APP_ENV,

    'api_tcm' : {
        'url' : 'https://api.tecnocampus.cat/webuntis/',
        'headers_api' : { headers: {
            'appkey': '$2y$10$0pnjfGXa3fN/E29uCK2q/un9Eg3U1xt.IZ4JfRVqRiD2kuBTaHw2C',
        }},
    }
});

export default config;