import {  combineReducers } from 'redux';
import NifReducer from './NifReducer';
import ConsultaReducer from './ConsultaReducer';
import ConfigReducer from './ConfigReducer';
import QuestionsReducer from './SurveyQuestionsReducer';
import {ScoreReducer, SaveScoreReducer} from './SurveyScoreReducer';
import HorariReducer from './Horari/HorariReducer';
import RedirectReducer from './RedirectReducer';


/**
 * Combinación de los diferentes reducers
 */
const mainReducer = combineReducers({
    redirect: RedirectReducer,
    config: ConfigReducer,
    nif: NifReducer,
    booking: ConsultaReducer,
    questions: QuestionsReducer,
    score: ScoreReducer,
    sendScore: SaveScoreReducer,
    untis : HorariReducer,

});

export default mainReducer;