export const CONFIG_SUCCESS = 'CONFIG';
export const CONFIG_FETCHING = 'CONFIG_FETCH';
export const CONFIG_ERROR = 'CONFIG_ERROR';

const initialState = {
    'status': 'void',
    'data': {},
    'error' : null
}

const ConfigReducer = (state=initialState, action) => {
    switch(action.type){
        case CONFIG_SUCCESS:
            return{
                ...state,
                status: 'success',
                data: action.payload
            }
        case CONFIG_FETCHING:
            return {
                ...state,
                status: 'fetching',
                data: {...state.data}
            }
        case CONFIG_ERROR:
            return {
                ...state,
                status : 'error',
                data: {...state.data},
                error: action.payload
            }
        default:
            return state;
    }
}

export default ConfigReducer;