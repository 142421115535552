import {CONFIG_ERROR,CONFIG_FETCHING,CONFIG_SUCCESS} from '../reducers/ConfigReducer';
import config from '../../config';
import axios from 'axios';

export const ConfigDispatch = () => (dispatch) =>{
    dispatch({type:CONFIG_FETCHING});

    axios.get(config.url_api + 'config',config.headers_api).then(response => {    
        dispatch({
            type: CONFIG_SUCCESS,
            payload: response.data
        })

    }).catch(error => {
        dispatch({
            type: CONFIG_ERROR,
            payload: error.response.data.message
        })
    })
}