import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import ReactInputMask from 'react-input-mask';
import useControlDNI from '../../hooks/useControlDNI';
import useHelpers from '../../hooks/useHelpers';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { ConfigDispatch } from '../../redux/actions/ConfigActions';
import { NifDispatch } from '../../redux/actions/NifActions';
import ConsultaDispatch from '../../redux/actions/ConsultaActions';

const FormConsulta = (props) => {
    const {  ErrorHttpRequest, emitError } = useHelpers();
    const [dni, setDni] = useState();
    const { checkDoc } = useControlDNI();

    //Dispatch reducer asincrono
    useEffect(() => {
        props.getConfig();
    }, [])

    const handleDni = (e) => {
        const value = e.target.value.toUpperCase();
        let valid = checkDoc(value);
        setDni(value);
        props.setNif(value);
    }


    const submitForm = (e) => {
        e.preventDefault();
        if (!dni) {
            return emitError('Error de camps', 'El dni no pot estar buit');
        }
        props.getBooking(dni, props.config.data.current_curs);
    }


    if (props.config.status === 'error') {
        return (
            <>
                {ErrorHttpRequest()}
                <Redirect to="/errors/500" />
            </>
        )
    }

    return (
        <>


            <LoadingOverlay style={{ 'position': 'fixed' }}
                active={props.config.status === 'fetching' || props.booking.status === 'fetching'}
                spinner
                text='Consultant servidor...'
            />
            <Card className="margin-bottom-20 animate__animated animate__zoomIn">
                <Card.Header className='bg-dark text-white'>
                    <h6>
                        Cercador de cita per a la matr&iacute;cula
                    </h6>
                </Card.Header>
                <form onSubmit={submitForm}>
                <Card.Body>
                    <div className="form-group margin-bottom-10">
                        <label htmlFor="Dni">DNI/NIF</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><FontAwesomeIcon icon={faIdCard} /> </span>
                            </div>
                            <ReactInputMask
                                mask="**************"
                                maskChar=""
                                autoComplete="new-password"
                                className='form-control input-sm'
                                type="text"
                                value={dni}
                                name='dni'
                                id='Dni'
                                onChange={handleDni}
                            />
                        </div>
                    </div>
                    <p>
                        <span>Curs Acad&egrave;mic: {props.config.data.label_curs}</span>
                    </p>

                </Card.Body>
                <Card.Footer>
                    <button  className="btn btn-primary">Consultar</button>
                </Card.Footer>
                </form>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        getConfig: () => dispatch(ConfigDispatch()),
        setNif: (nif) => dispatch(NifDispatch(nif)),
        getBooking: (nif,curs) => dispatch(ConsultaDispatch(nif,curs)),
    }
}
const FormConsultaRedux = connect(mapStateToProps, mapDispatchToProps)(FormConsulta);
export default FormConsultaRedux;