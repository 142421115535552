import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import useHelpers from '../../hooks/useHelpers';

const CardSuccess = (props) => {
    const  config  = props.config.data;
    const booking = props.booking.data;

    const cardRef = useRef();


    return (
        <Card ref={cardRef} className="animate__animated animate__fadeInDown animate__delay-1s">
            <Card.Header className="bg-secondary text-white">
                <h5>Dades per matricular-te al TecnoCampus  </h5>
            </Card.Header>
            <Card.Body>
                <table className="table">
                    <tr>
                        <th>DNI / Passaport:</th>
                        <td>{booking.dni_passaport}</td>
                    </tr>
                    <tr>
                        <th>Curs:</th>
                        <td>{config.label_curs}</td>
                    </tr>
                    <tr>
                        <th>Centre:</th>
                        <td>{booking.centre_tcm}</td>
                    </tr>
                    <tr>
                        <th>Estudis:</th>
                        <td>{booking.estudi_tcm}</td>
                    </tr>
                    <tr>
                        <th>Dia:</th>
                        <td style={{ 'font-size': '1.3em', 'font-weigth': 'bolder' }}>{booking.booking_data}</td>
                    </tr>
                    <tr>
                        <th>Hora:</th>
                        <td style={{ 'font-size': '1.3em', 'font-weigth': 'bolder' }}>{booking.booking_hora}</td>
                    </tr>
                    <tr>
                        <td colspan='2'>
                            Recorda que has de portar, emplenada i signada, tota la documentació que trobaràs en aquest <a href={config.web_info_matri} target="_blank" rel="noopener noreferrer">enllaç</a>
                        </td>
                    </tr>
                </table>
            </Card.Body>
            <Card.Footer>
                <ReactToPrint 
                trigger = {_=> <button className="btn btn-primary">Imprimir</button>}
                content = {_=> cardRef.current}
                />
               
            </Card.Footer>
        </Card>

    )
}

const setStateToProps = (state) => {
    return state
}

const setDispatchToProps = (dispatch) => {
    return {}
}

const CardSuccessRedux = connect(setStateToProps, setDispatchToProps)(CardSuccess);

export default CardSuccessRedux;