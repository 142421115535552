import {CONSULTA_SUCCESS, CONSULTA_FETCHING, CONSULTA_ERROR} from '../reducers/ConsultaReducer';
import axios from 'axios';
import config from '../../config'

const ConsultaDispatch = (dni,curs) => (dispatch) => {
    dispatch({type:CONSULTA_FETCHING});

    axios.get(config.url_api + 'booking?dni='+dni+'&curs='+curs,config.headers_api).then(response => {
        console.log(response.data);
        dispatch({
            type: CONSULTA_SUCCESS,
            payload: response.data
        })
    }).catch(error => {
        dispatch({
            type: CONSULTA_ERROR,
            payload: error.response.data.message
        })
    })

}

export default ConsultaDispatch