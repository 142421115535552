import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useHelpers from '../../hooks/useHelpers';
import { getHorarisDispatch, currentGrupDispatch, GrupDispatch } from '../../redux/actions/Horari/GrupActions';
import LoadingOverlay from 'react-loading-overlay';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import caLocale from '@fullcalendar/core/locales/ca'
import RedirectDispatch from '../../redux/actions/RedirectActions';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTh } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

const HorariGrup = (props) => {
    const GROUP_VIEW = 'group';
    const DETAIL_VIEW = 'detail';

    const { grup_id } = useParams();
    const { emitError, emitInfoHorari } = useHelpers();
    const [currentGrupName, setCurrentGrupName] = useState(null);
    const [navigate, setNavigate] = useState(null);
    const [currentView, setCurrentView] = useState(GROUP_VIEW);



    const fullcalendarRef = useRef();


    useEffect(() => {
        props.setRedirect(null);
        if (props.untis.grups.status !== 'success') {
            props.getGrups();
        } else {
            setTitle();
        }
        props.setHoraris(grup_id);

    }, []);




    /**
     * En caso de acceder a la pagina directemente tenemos que esperar a tener los grupos disponibles
     */
    useEffect(() => {
        setTitle();
    }, [props.untis.grups.status]);

    /**
     * Configura el texto del titulo en el momento en que tenemos los grupos disponibles
     */
    const setTitle = () => {
        let item = props.untis.grups.data.find(el => parseInt(el.untis_id) === parseInt(grup_id));
        if (item) {
            setCurrentGrupName(item.name);
        }
    }

    const navigateToMenu = () => {

        setNavigate('/horari');
    }

    const changeDayView = (e) => {
        if (e.view.type === 'dayGridMonth') {
            fullcalendarRef.current.getApi().changeView('timeGridDay', e.date);
        }
    }

    /**
     * Modal informativo con los detalles del evento al hacer click en el evento
     * @param {*} e 
     */
    const handleClick = (e) => {
        let id = e.event.id;
        let item = props.untis.horaris.data.find(el => parseInt(el.id) === parseInt(id));
        emitInfoHorari(item.data)
    }

    const setDetailView = () => {
        setCurrentView(DETAIL_VIEW);
        props.setHoraris(grup_id, DETAIL_VIEW);
    }

    const setGroupView = () => {
        setCurrentView(GROUP_VIEW)
        props.setHoraris(grup_id, GROUP_VIEW);
    }

    if (navigate) {
        return (
            <Redirect to={navigate} />
        )
    }


    return (
        <>
            <h6>{currentGrupName}</h6>
            <hr />
            <LoadingOverlay style={{ 'position': 'fixed' }}
                active={props.untis.horaris.status === 'fetching'}
                spinner
                text='Obtenint informació del servidor...'
            />

            {props.untis.horaris.status === 'error' &&
                <>
                    {emitError('Horari no trobat!', 'Aquest grup no té horaris configurats')}
                </>

            }
            {props.untis.current_grup !== null &&
                <>
                    <ReactTooltip />
                    <div className="row">
                        <div className="offset-sm-6 col-sm-6 pull-right">
                            <ButtonGroup toggle className="pull-right">

                                <ToggleButton
                                    data-tip="Vista agrupada"
                                    type="radio"
                                    variant="secondary"
                                    name="TypeView"
                                    checked={currentView === GROUP_VIEW}
                                    onClick={setGroupView}
                                >
                                    <FontAwesomeIcon icon={faTh} />
                                </ToggleButton>

                                <ToggleButton
                                    data-tip="Vista individual de grups"
                                    type="radio"
                                    variant="secondary"
                                    name="TypeView"
                                    checked={currentView === DETAIL_VIEW}
                                    onClick={setDetailView}
                                >
                                    <FontAwesomeIcon icon={faBars} />
                                </ToggleButton>

                            </ButtonGroup>

                        </div>
                    </div>

                    <FullCalendar
                        ref={fullcalendarRef}
                        headerToolbar={{
                            left: 'prev,next today,selectGroup',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                      
                        dateClick={changeDayView}
                        contentHeight={750}
                        expandRows={true}
                        allDaySlot={false}
                        expandRows={true}
                        slotMinTime='7:00:00'
                        slotMaxTime='22:00:00'
                        // eventClick={() => { alert('Evento!') }}
                        locale={caLocale}
                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        themeSystem='bootstrap'
                        events={props.untis.horaris.data}
                        eventClick={handleClick}
                        customButtons={{
                            selectGroup: {
                                "data-tip":"Vista individual de grups",
                                text: 'Grups',
                                click: () => { navigateToMenu() },
                            },
                            refreshData: {
                                text: 'Recargar',
                                click: () => { props.setHoraris(grup_id) }
                            }
                        }}

                    />
                </>
            }
        </>
    )
}

const mapStateToProps = (state) => (state);
const mapDispatchToProps = (dispatch) => {
    return {
        setRedirect: (to) => dispatch(RedirectDispatch(to)),
        getGrups: () => dispatch(GrupDispatch()),
        setHoraris: (id, type_grup = 'group') => dispatch(getHorarisDispatch(id, type_grup)),
        setCurrentGrup: (id = null) => dispatch(currentGrupDispatch(id))
    }
}

const HorariGrupRedux = connect(mapStateToProps, mapDispatchToProps)(HorariGrup);
export default HorariGrupRedux;