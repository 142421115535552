import React, { useState } from 'react';
import { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import Select from 'react-select';
import { GrupDispatch, getHorarisDispatch, checkHorariDispatch } from '../../redux/actions/Horari/GrupActions';
import useHelpers from '../../hooks/useHelpers';
import Swal from 'sweetalert2';
import { Redirect } from "react-router";
import RedirectDispatch from '../../redux/actions/RedirectActions';

const Horari = (props) => {
    const { emitError } = useHelpers();

    useEffect(() => {
        // console.log(props);
        props.getGrups();
    }, []);


    const setOptions = () => {
        return props.untis.grups.data.map(item => {
            return { value: item.untis_id, label: item.key }
        });
    }


    const handleGrupSelected = (e) => {
        let item = props.untis.grups.data.find(el => parseInt(el.untis_id) === parseInt(e.value));
        props.checkHorari(e.value);
    }

    if (props.redirect) {
        return (
            <>
                <Redirect to={props.redirect} />
            </>
        )
    }
    return (
        <>
            <LoadingOverlay style={{ 'position': 'fixed' }}
                active={props.untis.grups.status === 'fetching' || props.untis.horaris.status === 'fetching'}
                spinner
                text='Obtenint informació del servidor...'
            />

            <>
                <label>Selecciona el grup de classe per consultar horari:</label>
                <Select
                    options={setOptions()}
                    onChange={handleGrupSelected}
                />
            </>

            {props.untis.horaris.status === 'error' &&
                <>
                    {emitError('Horari no trobat!', 'Aquest grup no té horaris configurats')}
                </>

            }
        </>
    );
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {

    return {
        setRedirect: (to) => dispatch(RedirectDispatch(to)),
        getGrups: () => dispatch(GrupDispatch()),
        checkHorari: (id) => dispatch(checkHorariDispatch(id)),

    }
}

const HorariRedux = connect(mapStateToProps, mapDispatchToProps)(Horari);

export default HorariRedux;