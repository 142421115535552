export const UNTIS_GRUPS_SUCCESS = 'UNTIS_GRUPS_SUCCESS';
export const UNTIS_GRUPS_FETCHING = 'UNTIS_GRUPS_FETCH';
export const UNTIS_GRUPS_ERROR = 'UNTIS_GRUPS_ERROR';

export const UNTIS_HORARI_SUCCESS = 'UNTIS_HORARI_SUCCESS';
export const UNTIS_HORARI_FETCHING = 'UNTIS_HORARI_FETCH';
export const UNTIS_HORARI_ERROR = 'UNTIS_HORARI_ERROR';
export const UNTIS_CURRENT_GRUP = 'UNTIS_CURRENT_GRUP';

const initialState = {
    current_grup: null,
    grups: {
        'status': 'void',
        'data': [],
        'error': null
    },
    horaris: {
        'status': 'void',
        'data': [],
        'error': null
    }

}

const HorariReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNTIS_GRUPS_SUCCESS:
            return {
                ...state,
                grups: {
                    ...state.grups,
                    status: 'success',
                    data: action.payload
                }

            }
        case UNTIS_GRUPS_FETCHING:
            return {
                ...state,
                grups: {
                    ...state.grups,
                    status: 'fetching',
                    data: [...state.grups.data]
                }

            }
        case UNTIS_GRUPS_ERROR:
            return {
                ...state,
                grups: {
                    ...state.grups,
                    status: 'error',
                    data: [...state.data],
                    error: action.payload
                }


            }
        case UNTIS_CURRENT_GRUP:
            return {
                ...state,
                current_grup: action.payload
            }


        case UNTIS_HORARI_SUCCESS:
            return {
                ...state,
                horaris: {
                    ...state.horaris,
                    status: 'success',
                    data: action.payload,
                    error: null
                }

            }
        case UNTIS_HORARI_FETCHING:
            return {
                ...state,
                horaris: {
                    ...state.horaris,
                    status: 'fetching',
                    data: [...state.horaris.data]
                }

            }
        case UNTIS_HORARI_ERROR:
            return {
                ...state,
                horaris: {
                    ...state.horaris,
                    status: 'error',
                    data: [...state.horaris.data],
                    error: action.payload
                }


            }
        default:
            return state;
    }
}

export default HorariReducer;